
import { ref } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import {addMenu, deleteMenu, getMenuList, updateMenu} from "@/api/menu";
import {buildMenuTree} from "@/utils/treeBuilder";
import {getDictionaryDataAnon} from "@/api/dictionaryData";

export default {
  setup(){
    const menuOpen = ref(false);

    const validate: any = ref(null);

    const loading = ref(false);

    const store = useStore();

    const formSize = ref('large');

    const dialogTitle = ref('');

    const parentFormId = ref();

    const formRules = {
      menuName: [
        {
          required: true,
          message: '请输入菜单名',
        },
      ],
      menuType: [
        {
          required: true,
          message: '请选择菜单类型',
        },
      ],
      path: [
        {
          required: true,
          message: '请选择路由地址',
        },
      ],
    };


    const getHiddenDict = (hidden) => {
      let label: string;
      let type: string;
      if(hidden){
        label = '隐藏';
        type = 'warning';
      }else{
        label = '显示';
        type = 'success';
      }
      return {"label": label,"type": type};
    };


    const menuHidden = [
      {
        hidden: 1,
        label: '隐藏',
      },
      {
        hidden: 0,
        label: '显示',
      }
    ];
    //适配移动端
    if(store.state.app.isMobile){
      formSize.value = 'mini';
    }else{
      formSize.value = 'large';
    }

    const table:any = ref({
      loading: false,  // 加载中
      data: [],       // 表格数据
      isMobile: false,// 表格是否移动端
    });

    const searchParams: any = ref({});

    const menuForm: any = ref({});


    table.value.isMobile = store.state.app.isMobile;

    //获取菜单列表
    const listMenus = () => {
      table.value.loading = true;
          getMenuList({}).then((res: any) => {
            table.value.data = buildMenuTree(res.data,'id','parentId','children');
            table.value.loading = false;
          });
    }

    listMenus();

    const searchMenus = () => {
      table.value.loading = true;
      if(!searchParams.value.menuName && !searchParams.value.hidden) {
        listMenus();
      }else {
        getMenuList({
          menuName: searchParams.value.menuName,
          hidden: searchParams.value.hidden,
        }).then((res: any) => {
          table.value.data = res.data;
          table.value.loading = false;
        });
      }
    }

    const handleDelete = (id) => {
      deleteMenu(id).then((res: any) => {
        if(!res.errCode) {
          ElMessage.success("删除成功!");
          listMenus();
        }else{
          ElMessage.error(res.detail);
        }
      });
    }

    const handleEditClick = (row) => {
      const formData = JSON.parse(JSON.stringify(row)); //消除row的响应性
      dialogTitle.value = '修改菜单';
      menuForm.value = formData;
      menuOpen.value = true;
    }

    const onCancel = () => {
      menuOpen.value = false;
    }


    const submitForm = () => {
      if(parentFormId.value.getCheckedNodes().length > 0) {
        menuForm.value.parentId = parentFormId.value.getCheckedNodes()[0].value;
      }else {
        menuForm.value.parentId = 0;
      }

      if(menuForm.value.id) {
        //更新
        loading.value = true;
        updateMenu(menuForm.value).then((res: any) => {
          if(!res.errCode){
            ElMessage.success('更新菜单成功!');
            menuOpen.value = false;
            menuForm.value = {};
            listMenus();
          }else{
            ElMessage.error(res.detail);
          }
          loading.value = false;
        }).catch(() => {
          loading.value = false;
        });
      }else{
        //新增菜单
        validate.value.validate((valid) => {
          if(valid) {
            loading.value = true;
            addMenu(menuForm.value).then((res: any) => {
              if(!res.errCode){
                ElMessage.success('新建菜单成功!');
                menuOpen.value = false;
                menuForm.value = {};
                listMenus();
              }else{
                ElMessage.error(res.detail);
              }
              loading.value = false;
            }).catch(() => {
              loading.value = false;
            });
          }
        });
      }
    }


    return {
      table,
      searchParams,
      searchMenus,
      formSize,
      menuHidden,
      getHiddenDict,
      handleDelete,
      handleEditClick,
      submitForm,
      menuOpen,
      menuForm,
      loading,
      formRules,
      validate,
      dialogTitle,
      onCancel,
      parentFormId,
    };
  }
}
